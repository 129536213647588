import React from 'react';
import song1 from "../../assets/images/Frame 33670.png";
import song2 from "../../assets/images/Group 7229.png";
import song3 from "../../assets/images/image 261.png";
import song4 from "../../assets/images/image 307.png";
import song5 from "../../assets/images/image 308.png";
import song6 from "../../assets/images/image 309.png";
import backgroundImage from "../../assets/images/Frame 33629.png";
import liveData from "../../assets/data/Animation - 1725451177515.json";
// import Lottie from 'lottie-react';
import Header from '../Header';
import RadioCard from './RadioCard';
import DownloadLink from '../DownloadLink';
import Footer from "../Footer"

const Radio = () => {
    const text = "Hindi & English Radios";
    const images1 = [
        { image: song1, songName: "Bhakti Sangeet" },
        { image: song2, songName: "Lofi Songs" },
        { image: song3, songName: "90s songs" },
    ];
    const images2 = [
        { image: song4, songName: "Classic Rocks" },
        { image: song5, songName: "Today's hit songs" },
        { image: song6, songName: "80s alive" }
    ];

    return (
        <div className='text-white'>
            <div className='bg-gradient-to-tr from-black to-[#342C43] w-full h-[830px] z-10'>
                <Header image={backgroundImage} />
            </div>
            <div className='-mt-[730px] absolute z-30 w-full'>
                <div className='mx-24 flex flex-col gap-12 '>
                    <div>
                    <div className='flex gap-3 items-center'>
    <h1 className='font-bold text-[25.2px] ml-2'>Hindi Radio</h1>

    <div className='flex items-center gap-1'>
        {/* <Lottie 
            animationData={liveData} 
            className="lottie-small" 
        /> */}
        <h1 className='font-medium text-[15px]'>Live</h1>
    </div>
</div>

                        <div className='flex justify-between  flex-wrap mt-5 w-full'>
                            {images1.map((i, index) => (
                                <RadioCard key={index} image={i.image} songName={i.songName} />
                            ))}
                        </div>
                    </div>
                    <div>
                    <div className='flex gap-3 items-center'>
    <h1 className='font-bold text-[25.2px] ml-2'>English Radio</h1>

    <div className='flex items-center gap-1'>
        {/* <Lottie 
            animationData={liveData} 
            className="lottie-small" 
        /> */}
        <h1 className='font-medium text-[15px]'>Live</h1>
    </div>
</div>

                        <div className='flex justify-between mt-5 flex-wrap'>
                            {images2.map((i, index) => (
                                <RadioCard key={index} image={i.image} songName={i.songName} />
                            ))}
                        </div>
                        {/* <DownloadLink text={text} /> */}
                    </div>
                </div>
            </div>
            <div className='w-full'>
            <Footer/>
            </div>

        </div>
    );
};

export default Radio;
