import { useContext, useEffect, useState } from "react"
import React from 'react'
import { Howl } from "howler";
import { useRef } from "react";
import fire from "../../assets/images/Fire.png"
import Strom from "../../assets/images/Rain.png"
import Thunder from "../../assets/images/Thunder.png"
import Waves from "../../assets/images/waves.png"
import Wind from "../../assets/images/Wind.png"
import Birds from "../../assets/images/Birds.png"
import pauseIcon from "../../assets/images/pause.png"
import minusIcon from "../../assets/images/minussign.png"
import playIcon from "../../assets/images/play.png"
import "../Track.css"

const GuideTrack = ({image,title,About,song1}) => {
    const [currentTrack, setCurrentTrack] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioKey, setAudioKey] = useState(0);
    const [selectedEffects, setSelectedEffects] = useState([]);
    const [effectsSounds, setEffectsSounds] = useState([]);
    const audioRef = useRef(null);

    const [track1Progress, setTrack1Progress] = useState(0);
    const [track1CurrentTime, setTrack1CurrentTime] = useState("00:00");
    const [track1Duration, setTrack1Duration] = useState("00:00");

    

    const customStyle = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)',
        backdropFilter: 'blur(3px)',
    };

    useEffect(() => {
        if (currentTrack === null || !isPlaying) {
            effectsSounds.forEach(sound => sound.stop());
            setEffectsSounds([]);
            return;
        }

        effectsSounds.forEach(sound => sound.stop());

        const newEffectsSounds = selectedEffects.map(effectName => {
            const effectAudio = {
                Fire: "",
                Rain:"",
                Bird: "",
                Sun: "",
                Tree: "",
                Storm: "",
            }[effectName];

            return new Howl({
                src: [effectAudio],
                volume: 0.3,
                loop: true
            });
        });

        setEffectsSounds(newEffectsSounds);
        newEffectsSounds.forEach(sound => sound.play());

        return () => newEffectsSounds.forEach(sound => sound.stop());
    }, [selectedEffects, currentTrack, isPlaying]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (audioRef.current && audioRef.current.duration) {
                const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
                const currentTime = formatTime(audioRef.current.currentTime);
                const duration = formatTime(audioRef.current.duration);

                if (currentTrack === 1) {
                    setTrack1Progress(progress);
                    setTrack1CurrentTime(currentTime);
                    setTrack1Duration(duration);
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [audioRef, currentTrack, isPlaying]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const playTrack = (trackNumber) => {
        if (currentTrack === trackNumber) {
            if (isPlaying) {
                setIsPlaying(false);
                if (audioRef.current) {
                    audioRef.current.pause();
                }
            } else {
                setIsPlaying(true);
                if (audioRef.current) {
                    audioRef.current.play();
                }
            }
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
            }

            setCurrentTrack(trackNumber);
            setIsPlaying(true);
            setAudioKey(prevKey => prevKey + 1); 
        }
    };

    const handleEffectClick = (effectName) => {
        setSelectedEffects(prevSelected => {
            if (prevSelected.includes(effectName)) {
                return prevSelected.filter(e => e !== effectName);
            }

            if (prevSelected.length >= 2) {
                alert("You can select at most 2 sound effects.");
                return prevSelected;
            }

            return [...prevSelected, effectName];
        });
    };

    const handleProgressChange = (event, trackNumber) => {
        const newTime = (event.target.value / 100) * audioRef.current.duration;
        audioRef.current.currentTime = newTime;

        if (trackNumber === 1) {
            setTrack1Progress(event.target.value);
        } 
    };
  return (
    <div className='my-10 ml-16 mr-7  text-white mt-20'>
<div className="flex gap-10">
    <img src={image} className={"w-[400px] h-[300px]"}/>
<div>
    <div className="flex gap-10 mb-5 ">
                <div
                    className={`flex items-center px-3 py-5 h-16 rounded-2xl border border-gray-600 z-50  hover:bg-[#12111B]${
                        currentTrack === 1 ? 'bg-white/30' : ''
                    }`}
                    style={customStyle}
                >
                    <button
                        onClick={() => playTrack(1)}
                        className="bg-transparent text-white focus:outline-none mr-4"
                    >
                        <img
                            src={isPlaying && currentTrack === 1 ? playIcon : pauseIcon}
                            width={40}
                            alt="Play/Pause"
                            className='cursor-pointer'
                        />
                    </button>
                    <div className='flex flex-col'>
                        <span className="text-white font-semibold text-base p-2 cursor-default">{title}</span>
                        {currentTrack === 1 && (
                            <div className="mb-4 flex flex-col text-sm">
                                <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    value={track1Progress}
                                    onChange={(e) => handleProgressChange(e, 1)}
                                    className="w-full"
                                    style={{
                                        '--progress': `${track1Progress}%`
                                    }}
                                />
                                <div className="justify-between flex mt-1 mr-4 ml-2" >
                                <span className="text-white">{track1CurrentTime}</span>
                                
                                <span className="text-white">{track1Duration}</span>
                                    </div>

                            </div>
                        )}
                    </div>
                </div>

               
            </div>

            <audio
                key={audioKey}
                src={currentTrack === 1 ? song1:""}
                autoPlay={isPlaying}
                ref={audioRef}
                onEnded={() => setIsPlaying(false)}
                hidden
            />
           <h1 className="mb-7 text-[14px] font-semibold">{About}</h1>
            <div className="">
            <h4 className="text-white mb-4 font-semibold text-[16px]">Try Adding Effects To The Above Track</h4>
            <div className="flex gap-10">
                {[
                    { name: 'Fire', image: fire },
                    { name: 'Rain', image: Thunder },
                    { name: 'Bird', image: Birds },
                    { name: 'Sun', image: Waves },
                    { name: 'Tree', image: Wind },
                    { name: 'Storm', image: Strom }
                ].map(effect => (
                    <div key={effect.name} className="relative text-center">
                        <img
                            src={selectedEffects.includes(effect.name) ? `${effect.image}?v=${new Date().getTime()}` : effect.image}
                            alt={effect.name}
                            className="w-16 h-16 rounded-md cursor-pointer z-50"
                            onClick={() => handleEffectClick(effect.name)}
                        />
                        {selectedEffects.includes(effect.name) && (
                            <img
                                src={minusIcon}
                                alt="Minus"
                                className="absolute top-1 right-2 w-4 h-4"
                                style={{ transform: 'translate(50%, -50%)' }}
                            />
                        )}
                        <p className="text-white mt-2">{effect.name}</p>
                    </div>
                ))}
            </div>
            </div>
                </div>
</div>

</div>
  )
}

export default GuideTrack