import { useEffect, useState, useRef } from "react";
import React from 'react';
import { Howl } from "howler";
import fire from "../assets/images/Group 7404.webp";
import Thunder from "../assets/images/Group 7402.webp";
import Birds from "../assets/images/Group 7403.webp";
import pauseIcon from "../assets/images/pause.png";
import minusIcon from "../assets/images/minussign.png";
import playIcon from "../assets/images/play.png";
import "./Track.css";
import { useLocation } from "react-router-dom";

const Track = ({ image, width, song1, song2, song3, song4,name}) => {
    const [trackname,setTrackName]=useState(null);
    const location = useLocation();
    let isStory;
    if(location.pathname==="/stories")
        isStory=false;
    else
    isStory=true;
    const [tracks, setTracks] = useState([
        { id: 1, title: isStory ? "Track 1" : "Episode 1", progress: 0, currentTime: "00:00", duration: "00:00", song: song1 },
        { id: 2, title: isStory ? "Track 2" : "Episode 2", progress: 0, currentTime: "00:00", duration: "00:00", song: song2 },
        { id: 3, title: isStory ? "Track 3" : "Episode 3", progress: 0, currentTime: "00:00", duration: "00:00", song: song3 },
        { id: 4, title: isStory ? "Track 4" : "Episode 4", progress: 0, currentTime: "00:00", duration: "00:00", song: song4 }
    ]);
    
    const [currentTrack, setCurrentTrack] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioKey, setAudioKey] = useState(0);
    const [selectedEffects, setSelectedEffects] = useState([]);
    const [effectsSounds, setEffectsSounds] = useState([]);
    const audioRef = useRef(null);
    

    const customStyle = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)',
        backdropFilter: 'blur(3px)',
    };

    

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const playTrack = (trackNumber) => {
        if (currentTrack === trackNumber) {
            setIsPlaying(!isPlaying);
            if (audioRef.current) {
                isPlaying ? audioRef.current.pause() : audioRef.current.play();
            }
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
            }
            setCurrentTrack(trackNumber);
            setIsPlaying(true);
            setAudioKey(prevKey => prevKey + 1); 
        }
    };

    const handleEffectClick = (effectName) => {
        setSelectedEffects(prevSelected => {
            if (prevSelected.includes(effectName)) {
                return prevSelected.filter(e => e !== effectName);
            }
            if (prevSelected.length >= 2) {
                alert("You can select at most 2 sound effects.");
                return prevSelected;
            }
            return [...prevSelected, effectName];
        });
    };

    useEffect(() => {
        if (currentTrack === null || !isPlaying) {
            effectsSounds.forEach(sound => sound.stop());
            setEffectsSounds([]);
            return;
        }

        effectsSounds.forEach(sound => sound.stop());

        const newEffectsSounds = selectedEffects.map(effectName => {
            const effectAudio = {
                Fire: fire,
                Rain: Thunder,
                Bird: Birds,
                
            }[effectName];

            return new Howl({
                src: [effectAudio],
                volume: 0.3,
                loop: true
            });
        });

        setEffectsSounds(newEffectsSounds);
        newEffectsSounds.forEach(sound => sound.play());

        return () => newEffectsSounds.forEach(sound => sound.stop());
    }, [selectedEffects, currentTrack, isPlaying]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (audioRef.current && audioRef.current.duration) {
                const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
                const currentTime = formatTime(audioRef.current.currentTime);
                const duration = formatTime(audioRef.current.duration);

                setTracks((prevTracks) => prevTracks.map((track, index) => 
                    index === currentTrack - 1
                        ? { ...track, progress, currentTime, duration }
                        : track
                ));
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [audioRef, currentTrack, isPlaying]);


    const handleProgressChange = (event, trackNumber) => {
        const newTime = (event.target.value / 100) * audioRef.current.duration;
        audioRef.current.currentTime = newTime;

        setTracks((prevTracks) =>
            prevTracks.map((track, index) => 
                index === trackNumber - 1
                    ? { ...track, progress: event.target.value }
                    : track
            )
        );
    };

    const handleSoundscapeClick = () => {
        window.open('https://play.google.com/store/apps/details?id=com.music.focusflow&pcampaignid=web_share', '_blank');
    };
    useEffect(()=>{
console.log(trackname)
    },[trackname])

    return (
        <div className="mt-10 ml-10 text-white">
            
              <div className={`bg-white  cursor-pointer ${location.pathname!=="/mood"?"hover:mt-[7.2vh]":"hover:mt-5"} absolute w-auto h-auto right-20 text-black text-sm hover:bg-[#d9d9d9] p-2  rounded-md font-extrabold ${location.pathname==="/mood"?"mt-4":"mt-10"}`} onClick={()=>{
                handleSoundscapeClick()
              }}><span className="relative">Listen more </span>
               <span className="relative">&gt;</span>
                </div>
            
            <div className="flex  gap-12 ">
                <img src={image} className={`${width}  mt-10`} alt="Track Image" />
                <div className="">
               {location.pathname==="/top-playlist"&&<h1 className={`${trackname===name?"text-3xl":"text-[45px]"} font-bold mt-10`}>{name}</h1>}
               {location.pathname==="/stories"&&<h1 className={`${trackname===name?"text-3xl":"text-[38px]"} font-bold mt-10`}>{name}</h1>}
                    <div className={`flex flex-wrap gap-8 ${location.pathname==="/mood"?"mt-24":"mt-12"}`}>
                      
                        {tracks.map((track) => (
                            
                            <div
                                key={track.id}
                                className={` items-center   rounded-2xl border border-gray-600 z-50 hover:bg-[#12111B] ${currentTrack === track.id ? 'bg-white/30 h-auto px-4 py-2 w-[20vw] -mt-10 ' : 'h-20 px-3 py-4'}`}
                                style={customStyle}
                                onClick={()=>{
                                
                                  setTrackName(name);
                                    
                                }}
                            >
                                <div className="flex ">
                                <button
                                    onClick={() => playTrack(track.id)}
                                    className="bg-transparent text-white focus:outline-none mr-4"
                                >
                                    <img
                                        src={isPlaying && currentTrack === track.id ?playIcon:pauseIcon }
                                        width={40}
                                        alt="Play/Pause"
                                        className="cursor-pointer"
                                    />
                                </button>
                                <div className="flex flex-col">
                                    <span className={`text-white font-semibold text-[20px] p-2 cursor-default  ${currentTrack===track.id?"text-sm":""}`}>{track.title}</span>
                                    {currentTrack === track.id && (
                                        <div className="mb-4 flex flex-col text-sm w-full">
                                            <input
                                                type="range"
                                                min="0"
                                                max="100"
                                                value={track.progress}
                                                onChange={(e) => handleProgressChange(e, track.id)}
                                                className=""
                                                style={{ '--progress': `${track.progress}%` }}
                                            />
                                            <div className="justify-between flex mt-1 mr-4 ml-2">
                                                <span className="text-white">{track.currentTime}</span>
                                                <span className="text-white">{track.duration}</span>
                                            </div>

                                            
                                        </div>
                                    )}
                                </div>
                                    </div>
                            { currentTrack===track.id&&       <div>
                        <h4 className="text-white mb-4 font-semibold  text-[14px] mt-2">Add Effects</h4>
                        <div className="flex  gap-5">
                            {[
                                { name: 'Fire', image: fire },
                                { name: 'Rain', image: Thunder },
                                { name: 'Bird', image: Birds },
                               
                            ].map(effect => (
                                <div key={effect.name} className="relative text-center">
                                    <img
                                        src={selectedEffects.includes(effect.name) ? `${effect.image}?v=${new Date().getTime()}` : effect.image}
                                        alt={effect.name}
                                        className="w-12 h-12 rounded-md cursor-pointer z-50"
                                        onClick={() => handleEffectClick(effect.name)}
                                    />
                                    {selectedEffects.includes(effect.name) && (
                                        <img
                                            src={minusIcon}
                                            alt="Minus"
                                            className="absolute top-0 right-0 w-4 h-4 rounded-full bg-white cursor-pointer -mt-2 -mr-2"
                                            onClick={() => handleEffectClick(effect.name)}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>}
                            </div>

                            
                            
                        ))}
                        <audio
                            key={audioKey}
                            src={tracks.find(track => track.id === currentTrack)?.song}
                            autoPlay={isPlaying}
                            ref={audioRef}
                            onEnded={() => setIsPlaying(false)}
                            hidden
                        />
                    </div>
                    
                    
                </div>
            </div>
        </div>
    );
};

export default Track;
