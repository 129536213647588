import React, { useState } from 'react';
import MobilePhone from "../../../assets/images/HoverImage22 (2).png";
import BackgroundImage2 from "../../../assets/images/BackgroundImage2.png";
import HoverImage from "../../../assets/images/HoverImage22 (1).png";
import ExploreButton from './ExploreButton';
import { useNavigate } from 'react-router-dom';

const FourthSection = () => {
    const customStyle = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)',
        backdropFilter: 'blur(3px)',
    };
    const navigate = useNavigate();

    return (
        <section className=' text-white pt-[25vh] pb-[45vh] relative h-auto bg-gradient-to-b from-[#38395C] to-[#31314E]' id="sleep-section">
            <img src={BackgroundImage2} className='absolute h-[55vh] w-full' alt="Background" />
            <div className='flex ml-[8vw] mr-[25vw] image-container'>
                <div className='w-full shadow-2xl shadow-black mt-[9vh] h-auto pb-[5vh] rounded-lg pl-[4vw] pr-[6vw] pt-[4vh] text-center' style={customStyle}>
                    <h1 className='text-[2.5vw] font-bold mb-[2vh]'>Soothing Sleep Stories
                    </h1>
                    <h3 className='mb-[2vh] text-[1.3vw]'>Find your ideal bedtime companion with Sleep Stories, thoughtfully collected to calm your mind and lead you into a deep, restorative sleep every night. Each story is tailored to help you relax, let go of the day’s stresses, and drift into a peaceful sleep.</h3>

                    <ExploreButton path={'/stories'} />

                </div>
                <div className='-mt-[28vh] z-40 -ml-[8vw]'>
                    <img
                        src={MobilePhone}
                        className='w-[30vw] h-[110vh] default-image z-50 -mt-[1vh] '
                        alt="Mobile Phone"
                    />
                    <img
                        src={HoverImage}
                        className='w-[30vw] h-[110vh] hovered-image z-50 -mt-[1vh]  '
                        alt="Mobile Phone Hover"
                    />
                </div>

            </div>
        </section>
    );
};

export default FourthSection;
