import { useNavigate ,useLocation} from "react-router-dom";
import logo from "../assets/images/Frame 33599.png";
import { useEffect,useState } from "react";


const Header = ({ image }) => {
    const location = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);

    const navigate = useNavigate();
    const menu = [
        { name: "Top Playlist", targetId: "top-playlist", path: "/top-playlist" },
        { name: "Moods", targetId: "mood", path: "/mood" },
        { name: "Stories", targetId: "stories", path: "/stories" },
        { name: "Guided Meditations", targetId: "guided-meditations", path: "/guided" },
        { name: "Brainwaves", targetId: "brainwaves", path: "/brainwaves" },
        { name: "Radio", targetId: "radio", path: "/radio" },
        { name: "Blogs", targetId: "blogs", path: "/blog" },
    ];

    const customStyle = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
        backdropFilter: 'blur(3px)',
        backgroundColor: isScrolled ? '#12111B' : '',
        transition: 'background-color 0.3s ease',
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0); // Change 0 to the desired scroll position if needed
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className="">
            <div className="relative">
                {image && (
                    <img
                        src={image}
                        className={`absolute ${location.pathname === "/blog-des" ? "opacity-20" : "opacity-50"} ${location.pathname === "/blog-des" ? "h-[120vh]" : "h-[50vh]"} w-full`}
                        alt="background"
                    />
                )}

                <div className=" fixed px-5 flex gap-[6vw] z-50 items-center justify-between h-[10vh] w-full" style={customStyle}>
                    <img
                        src={logo}
                        alt="logo"
                        
                        className="cursor-pointer py-4 w-[20vw]"
                        onClick={() => navigate('/')}
                    />

                    <div className="flex justify-end text-[1.3vw]  gap-[2vw] font-semibold text-center">
                    {menu.map((item, index) => (
    <div
        key={index}
        onClick={() => navigate(item.path)}
        className="cursor-pointer relative flex items-center justify-center px-3 py-7 z-50"
    >
        {location.pathname === item.path && (
            <div className="absolute inset-0 bg-white/30 h-[5vh] mt-7 rounded-md"></div>
        )}
        <div className="relative text-white">
            <span
                className={`${
                    location.pathname !== item.path
                        ? 'hover:border hover:px-2 hover:border-white hover:font-semibold hover:rounded-md hover:py-1'
                        : 'font-semibold'
                }`}
            >
                {item.name}
            </span>
        </div>
    </div>
))}

                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
