import React, { useEffect, useRef } from 'react';
import backgroundImage from "../../assets/images/BackgroundMoodImage.png";
import Angry from "../../assets/images/Angry.png";
import Distracted from "../../assets/images/Distracted.png";
import Sad from "../../assets/images/Sad.png";
import Ani from "../../assets/images/Ani.png";
import Tired from "../../assets/images/Frame 33623.png";
import Romantic from "../../assets/images/Frame 33624.png";
import Header from '../Header';
import Track from '../Track';
import Footer from '../Footer';
import { useLocation, useParams } from 'react-router-dom';

const Mood = () => {
    const { moodName } = useParams();
    const width = "w-[230px] h-[230px]";
    const moodRefs = useRef({});

    const moodplaylist = [
        { songName: Angry, name: "Angry" },
        { songName: Tired, name: "Tired" },
        { songName: Distracted, name: "Distracted" },
        { songName: Ani, name: "Ani" },
        { songName: Romantic, name: "Romantic" },
        { songName: Sad, name: "Sad" },
    ];
    const location = useLocation();

    const itemHeight = 345; 
    const height = moodplaylist.length * itemHeight; 

    useEffect(() => {
        if (moodName && moodRefs.current[moodName]) {
            moodRefs.current[moodName].scrollIntoView({ behavior: 'smooth' });
        }
    }, [moodName]);

   

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1)); 
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  

    return (
        <div className='relative'>
            <div 
                className='bg-gradient-to-tr from-black to-[#342C43] w-full min-h-screen max-h-full z-10'
                style={{ minHeight: `${height}px` }} 
            >
                <Header image={backgroundImage} />
            </div>

            <div className='z-20 absolute top-0 left-0 mt-20 w-full'>
                {moodplaylist.map((i, index) => (
                    <section
                        key={index}
                        ref={(el) => (moodRefs.current[i.name] = el)} 
                          id={i.name}
                    >
                        {console.log(i.name)}
                        <Track image={i.songName} width={width}  />
                    </section>
                ))}
            </div>
            <Footer />
        </div>
    );
};

export default Mood;
