import React from 'react';

const Menu = ({ item, isSelected, onClick }) => {
  const customStyle = {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)',
    backdropFilter: 'blur(7px)',
    color: 'white', 
    cursor: 'pointer',
  };

  return (
    <div
      className={`rounded-3xl border hover:bg-[#12111B]  border-[#3D3C3F] py-2 px-7 text-[15px] font-semibold ${isSelected?"bg-gradient-to-br from-white/50 to-[#474055]":""}`}
      style={customStyle}
      onClick={onClick}
    >
      {item}
    </div>
  );
};

export default Menu;
