import React, { useState } from 'react'
import image from "../assets/images/Vector 226.png"
import Header from './Header'
import Footer from './Footer';
const ContactUs = () => {
    const [name,setName]=useState("");
    const [email,setEmail]=useState("")
    const [subject,setSubject]=useState("")
    const [bodyline,setBodyline]=useState("")
  return (
    <div className='bg-gradient-to-r from-black to-[#403754] text-white'>
        <Header/>
        <div className='flex gap-10'>
            <img src={image} className='w-[600px]'/>
            <div className='mt-10'>
          <h1 className='text-[70px] font-bold'>Contact Us</h1>
          <form  className="space-y-4 w-[500px]">
          
          <div>
            <label className='text-[16.8px] font-medium'>Name</label>
            <input
              type="text"
              placeholder="Type here..."
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full my-2 p-3 bg-transparent border border-white rounded-full  focus:outline-none"
            />
          </div>
          <div>
            <label className='text-[16.8px] font-medium'>Email ID</label>
            <input
              type="email"
              placeholder="Type here..."
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
               className="w-full my-2 p-3 bg-transparent border border-white rounded-full text-[16.8px] focus:outline-none "
             
            />
          </div>
          <div>
            <label className='text-[16.8px] font-medium'>Subject Line</label>
            <input
              type="text"
              placeholder="Type here..."
              value={subject}
              required
              onChange={(e) => setSubject(e.target.value)}
               className="w-full my-2 p-3 bg-transparent border border-white rounded-full text-[16.8px] focus:outline-none "
             
            />
          </div>
          <div>
            <label className='text-[16.8px] font-medium'>Add your issue</label>
            <textarea
            placeholder="Type here..."
            value={bodyline}
            required
            onChange={(e) => setBodyline(e.target.value)}
            className="w-full my-2 p-3 bg-transparent border border-white rounded-3xl text-[16.8px] focus:outline-none resize-none h-[120px]"
                            />

          </div>
          <div className="text-center mx-20">
            <button
              type="submit"
              className="w-full mt-5 py-3 bg-[#757278] rounded-full text-[16.8px] font-semibold focus:outline-none"
            >
                Submit
              
            </button>
          </div>
        </form>
            </div>

        </div>
      <Footer/>
    </div>
  )
}

export default ContactUs