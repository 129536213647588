import React from 'react';
import MobilePhone from "../../../assets/images/Reactangle 8 (2).png";
import HoverImage from "../../../assets/images/Reactangle 8 (1).png"
import BackgroundImage3 from "../../../assets/images/BackgroundImage3.png";
import ExploreButton from './ExploreButton';
import { useNavigate } from 'react-router-dom';
const SixthSection = () => {
    const customStyle = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)',
        backdropFilter: 'blur(3px)',
    };
    const navigate=useNavigate();

    return (
        <section className=' text-white  relative pb-[50vh]' >
            <img src={BackgroundImage3} className='absolute h-[55vh] w-full ' alt="Background" />
            <div className='flex ml-[8vw] mr-[25vw] image-container'>
                <div className=' shadow-2xl shadow-black mt-[9vh] h-auto pb-[5vh] rounded-lg pl-[4vw] pr-[6vw] pt-[4vh] text-center  w-full' style={customStyle}>
                    <h1 className='text-[2.5vw] font-bold mb-[2vh]'>Online Radio
                    </h1>
                    <h3 className='mb-[2vh] text-[1.3vw] mx-[2vw] '>Enjoy your favorite stations anytime, anywhere with our Radio collection. Offering endless soothing tunes and calming melodies, this online FM radio brings you relaxing sounds to help you rest no matter where you are.

                    </h3>
                 
<ExploreButton path={'/radio'} />
                </div>
                {/* <img src={MobilePhone3} className='w-[314.3px] h-[596.4px] absolute left-[900px] -mt-36 z-20' alt="Mobile Phone" /> */}
                <div className='-mt-[28vh] z-40 -ml-[8vw]' id="radio-section">
<img
          src={MobilePhone}
          className='w-[29vw] h-[110vh] default-image z-50  -mt-[1vh] '
          alt="Mobile Phone"
        />
        <img
          src={HoverImage}
          className='w-[29vw] h-[110vh] hovered-image z-50  -mt-[1vh]  '
          alt="Mobile Phone Hover"
        />
</div>
            </div>
        </section>
    );
};

export default SixthSection;
