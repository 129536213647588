import React from 'react';
import backgroundImage from "../../assets/images/BackgroundImageStory.png";
import Header from '../Header';
import story1 from "../../assets/images/Group 7381.webp";
import story2 from "../../assets/images/Group 7382.webp";
import story3 from "../../assets/images/Group 7383.webp";
import story4 from "../../assets/images/Group 7384.webp";
import story5 from "../../assets/images/Group 7385.webp";
import story6 from "../../assets/images/Group 7386.webp";
import Track from '../Track';
import Footer from '../Footer';

const Stories = () => {

   
    const width = "w-[300px] h-[235px]";
    const storyplaylist = [
        { story: story1 ,storyName:"The Young Scout"},
        { story: story2,storyName:"The Myths and Legends across the World" },
        { story: story3 ,storyName:"The Starry Journey"},
        { story: story4 ,storyName:"The Scar"},
        { story: story5 ,storyName:"A Machine that Flies"},
        { story: story6 ,storyName:"The Tale of Squirrel Nutken"}
    ];
    const text="All Stories"
    // Calculate the height dynamically based on the number of story items
    const itemHeight = 350; // approximate height of each Track (including margin)
    const height = storyplaylist.length * itemHeight; // total height



    return (
        <div className='relative'>
            {/* Apply dynamic height to background */}
            <div 
                className='bg-gradient-to-tr from-black to-[#342C43] w-full min-h-screen max-h-full z-10'
                style={{ minHeight: `${height}px` }} // Apply calculated height
            >
                <Header image={backgroundImage} />
            </div>

            {/* Absolute positioning for story items */}
            <div className='z-20 absolute top-0 left-0 mt-20 w-full'>
                {storyplaylist.map((i, index) => (
                    <Track key={index} image={i.story} width={width} name={i.storyName} />
                ))}
                
            </div>
            <Footer/>
        </div>
    );
};

export default Stories;
