// App.jsx
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import TopPlaylist from './components/Top-Playlist/TopPlaylist';
import Mood from './components/Mood/Mood';
import Stories from './components/Stories/Stories';
import BrainWaves from './components/Brainwaves/BrainWaves';
import Guided from './components/Guided/Guided';
import Radio from './components/Radio/Radio';
import Blog from './components/Blogs/Blog';
import BlogDes from './components/Blogs/BlogDes';
import PrivacyPolicy from './components/PrivacyPolicy';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
// import Header from './components/Header';
import Footer from './components/Footer';
import GAListener from './GAListener';
import { initGA } from './initGA';

const App = () => {
  useEffect(() => {
    initGA();
  }, []);

  return (
    <div className='wix-madefor-text-bold'>
      <Router>
        <GAListener />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/top-playlist' element={<TopPlaylist />} />
          <Route path='/mood' element={<Mood />} />
          <Route path='/stories' element={<Stories />} />
          <Route path='/brainwaves' element={<BrainWaves />} />
          <Route path='/guided' element={<Guided />} />
          <Route path='/radio' element={<Radio />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog-des' element={<BlogDes />} />
          <Route path='/privacy' element={<PrivacyPolicy />} />
          <Route path='/about-soundscape' element={<AboutUs />} />
          <Route path='/contact' element={<ContactUs />} />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </div>
  );
};

export default App;
