import React from 'react'
import { useNavigate } from 'react-router-dom';

const ExploreButton = ({path}) => {
  const navigate=useNavigate();
  const handleExploreClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path);
};

  return (
    <div onClick={()=>{
      handleExploreClick()
    }}
    className='hover:mt-[1vh]'>
         <button className="bg-white hover:bg-[#d9d9d9] text-center text-black px-[3vw] py-[1vh] rounded-full font-extrabold text-[1vw]">
                Explore now
            </button>
    </div>
  )
}

export default ExploreButton