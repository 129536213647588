import React from 'react';
import arrow from "../../../assets/images/tabler_chevron-up.webp";

const QuestionSection = () => {
    const questions = [
        {name: "Soundscape", questions: ["What is a soundscape?", "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", "What are the benefits of listening to soundscapes?","Do you offer guided meditation or relaxation sessions with soundscapes?"]},
        {name: "Sleep", questions: ["How to sleep better?","How do you find rain sounds for sleeping?","Can sleep stories help you to sleep better?"]},
        {name:"Meditation",questions:["How to meditate?","Can we do Meditation for sleep?","Are there any free meditation apps available?"]},
        {name:"Stress",questions:["What is stress?","How to reduce stress?","How to prevent anxiety and stress?"]},
        {name:"Mental Health",questions:["Why is mental health important?","What does mental health mean?","How to improve mental health?"]},
        {name:"Binaural Beats",questions:["What are the benefits of listening to binaural beats?","How can a brainwave app assist in improving mental clarity and focus?"]}
    ];

    return (
        <div className='text-white bg-gradient-to-br from-[#2B2B45] via-[#130515] via-95%  to-black pt-[15vh] pb-[10vh]'>
            <h1 className='text-center font-bold text-[4vw] mb-[10vh]'>Frequently Asked Questions</h1>
            <div className='mx-[10vw]'>
                {questions.map((q, index) => (
                    <div key={index} className='mb-[8vh]'>
                        <h3 className='mb-[2vh] font-bold text-[2.5vw]'>{q.name}</h3>
                        {q.questions.map((i, idx) => (
                            <div key={idx}>

                                <div className='flex justify-between mb-[2vh] py-[1vh] text-[1.3vw]'>
                                    <div>{i}</div>
                                    <img src={arrow} alt="arrow" className='w-[1.5vw] h-[2.5vh]'/>
                                </div>
                                <hr className='border border-white border-1 border-opacity-10' />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default QuestionSection;
