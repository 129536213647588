import React from 'react'
import box from "../assets/images/Rectangle 272.png"
const AboutCard = ({data}) => {
    // console.log(image);
  return (
    <div className='w-[348px] h-auto border border-[#464249] rounded-lg '>
        <div className='flex'>
        <img src={data.image}  className='h-[67.25px] '/>
        <div className='absolute ml-[90px]'>
        <img src={box}  className='h-[58px] w-[257px]'/>
        <h1 className='-mt-12 ml-12 text-[26.6px] font-bold'>{data.title}</h1>
          </div>
        </div>
        <p className='px-7 pb-3 pt-5 text-justify text-[15px]'>{data.des}</p>

    </div>
  )
}

export default AboutCard