import React, { useEffect, useState, useRef } from 'react';
import Angry from "../../../assets/images/Angry.png";
import Distracted from "../../../assets/images/Distracted.png";
import Sad from "../../../assets/images/Sad.png";
import Ani from "../../../assets/images/Ani.png";
import Tired from "../../../assets/images/Frame 33623.png";
import Romantic from "../../../assets/images/Frame 33624.png";
import { useNavigate } from 'react-router-dom';
import PlayIcon from '../../../assets/images/PlayIcon.svg';

const ThirdSection = () => {
    const moods = [
        { image: Angry, name: "Angry" },
        { image: Distracted, name: "Distracted" },
        { image: Sad, name: "Sad" },
        { image: Ani, name: "Ani" },
        { image: Tired, name: "Tired" },
        { image: Romantic, name: "Romantic" }
    ];

    const [datamood, setDatamood] = useState([...moods, ...moods]);
    const [isHovered, setIsHovered] = useState(null); // Track the hovered image index
    const scrollContainerRef = useRef(null);
    const navigate = useNavigate();

    const handleExploreClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        navigate('/mood');
    };

    const addMoreImages = () => {
        setDatamood((prev) => [...prev, ...moods]); 
    };

    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && isHovered === null) { // Only scroll if no image is hovered
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
            if (scrollLeft + clientWidth >= scrollWidth - 1) {
                addMoreImages();  
            }
        }
    };

    const handleMoodClick = (moodName) => {
        navigate(`/mood#`+moodName);
    };

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
            return () => {
                scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [isHovered]); 

    return (
        <section className="text-white pt-[20vh] pb-[2vh] opacity-100 bg-gradient-to-t from-[#0E0C12]">
            <div ref={scrollContainerRef} className="scroll-container ">
                <div className="scroll-background-left-img flex  justify-between overflow-hidden py-[5vh] ">
                {datamood.map((mood, index) => (
  <div
    key={index}
    onMouseEnter={() => setIsHovered(index)}
    onMouseLeave={() => setIsHovered(null)}
    onClick={() => handleMoodClick(mood.name)}
    className="relative cursor-pointer"
  >
    <img
      src={mood.image}
      alt={`Mood ${index}`}
      style={{
        transform: isHovered === index ? 'scale(1.15)' : 'scale(0.8)',
        transition: 'transform 0.3s ease', // smooth scaling for both hover and non-hover states
      }}
      className={`transform transition duration-300 w-[20vw] h-[40vh] ${isHovered === index ? 'opacity-50' : ''}`}
    />

    {isHovered === index && (
      <img
        src={PlayIcon}
        alt="Play Button"
        className="absolute inset-0 w-[6vw] h-[6vw]"
        style={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)', // this ensures the icon is perfectly centered
          pointerEvents: 'none',
        }}
      />
    )}
  </div>
))}


                </div>
            </div>
            <div className='justify-center flex '>
            <button className="bg-white hover:bg-[#d9d9d9] text-center text-black px-[3vw] py-[1vh] rounded-full font-extrabold text-[1.5vw] mt-[7vh] hover:mt-[7.5vh]"
            onClick={()=>{
                handleExploreClick();
            }}>
              <h1 className="">Explore now</h1>  
            </button>
            </div>
        </section>
    );
};

export default ThirdSection;
