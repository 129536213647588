import React from 'react';
import DP from "../../../assets/images/DP.png";
import ReviewCard from './Cards/ReviewCard';

const LastSection = () => {
  return (
    <section className='text-white pt-[15vh] bg-gradient-to-tr from-[#2B2B45]  to-[#130515]'>
      <h1 className='text-center font-bold text-[4vw]'>Hear from our users</h1>
      <div className='flex justify-center items-center mx-[2vw] mt-[5vh]'>
        <ReviewCard image={DP}/>
        <ReviewCard image={DP}/>
      </div>
    </section>
  );
}

export default LastSection;
