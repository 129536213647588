import React from 'react'
import { useNavigate } from 'react-router-dom'

const BlogCard = ({image,des,width}) => {
  const handleExploreClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate('/blog-des');
};

  const navigate=useNavigate();
  return (
    <div className='py-[6vh] px-[1vw]'>
    <div className='w-[40vw] h-auto shadow-[#000000] shadow-xl text-white p-[1.5vw] rounded-xl cursor-pointer transform transition-transform duration-300 hover:scale-110 ' onClick={()=>{
      handleExploreClick();
    }}>
        <img src={image}  className='w-full h-[42vh]' alt={image}/>
        <h3 className='text-[1vw] font-semibold mt-[2vh]'>{des.category}</h3>
        <h1 className='text-[1.8vw] font-bold mb-[2vh]'>{des.title}</h1>
        <h3 className='text-[1.2vw] mb-[2vh] font-semibold'>{des.des}</h3>
        <h6 className='text-[#00A3EA] text-[1vw] font-bold'>{des.pra}</h6>
    </div>
    </div>
  )
}

export default BlogCard;