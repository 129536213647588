import React from 'react'
import MobilePhone from "../../../assets/images/Rectangle.webp"
import Image from "../../../assets/images/Polygon 4.webp"
import { useNavigate } from 'react-router-dom';
const HomeSection = () => {
    const navigate=useNavigate();
  const handleExploreClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate("/top-playlist");
};
  return (
    <div className='text-white z-50  flex gap-5 mx-[8vw]'>
        <div className='w-1/2 mt-[15vh]'>
        <h1 className='font-bold text-[3vw]  mt-[1vh]'>Soundscape your perfect music platform for a balanced lifestyle. </h1>
        <p className='text-[1.5vw] mt-[7vh]'>Enhance your rest with brainwaves and explore hundreds of guided meditations, soothing sleep music tracks and calming stories, all designed to help you achieve a harmonious, balanced lifestyle.</p>
        
         <button className="bg-white text-center text-black  rounded-full font-bold text-[1.5vw] px-[5vh] hover:mt-[7.5vh] hover:bg-[#d9d9d9] py-[0.5vw] mt-[7vh]"
         onClick={()=>{
          handleExploreClick()
        }}>
            PLAY
            </button>
  

        </div>

<div className='w-1/2 relative mt-[15vh] '>

    <img src={Image} alt="listening music" className='w-[35vw] h-[75vh] mt-2 absolute -right-[1vw]'/>
    <img src={MobilePhone} alt="Playlist " className='w-[25vw] h-[90vh] absolute -mt-4 right-[18vw]'/>
</div>
    </div>
  )
}

export default HomeSection