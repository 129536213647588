import React from 'react'
import VideoImage from "../../../assets/images/Group 7368.png"
const VideoSection = () => {
  return (
    <section className='py-[40vh] text-white '>
         <h1 className="text-center wix-madefor-text-bold font-bold text-[2.5vw]">
                Sound solutions for your every Mood
            </h1>

        <div className='flex justify-center'>
            <img src={VideoImage} className='h-[70vh] w-[70vw]'/>
            <iframe  src="https://www.youtube.com/embed/F7_l28feM5A?si=TquimDwD0v4JKb90" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen className='absolute mt-[8vh] rounded-xl w-[49.5vw] h-[56.8vh]'></iframe>
        </div>
    </section>
  )
}

export default VideoSection