import React from 'react'

const ReviewCard = ({image}) => {
    const customStyle = {
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.9)',
    };

    return (
        <div className='w-[60vw] h-auto flex gap-[1vw] text-white px-[1vw] pt-[2vh]  pb-[1vh] rounded-xl mr-[2vw]' style={customStyle}>
            <img src={image} className='w-[5vw] h-[10vh]' />
            <div className='mb-[3vh]'>
                <h1 className='text-[1.8vw]'>
                    App is so good that I want to listen to songs every day...
                </h1>
                <h3 className='text-[1vw] mt-[2vh]'>Riya Goyal</h3>
            </div>
        </div>
    );
}

export default ReviewCard;
