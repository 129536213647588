import React from 'react';
import yoga from "../../../assets/images/image 276.webp";
import love from "../../../assets/images/Group 7320.webp";
import SliderComponent from './Slider/Slider';

const EightSection = () => {
    const blogData = [
        { image: yoga, des: {category:"Mental Health", title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        { image: love, des: { category:"Mental Health",title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        { image: yoga, des: { category:"Mental Health",title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        { image: love, des: {category:"Mental Health", title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        { image: yoga, des: { category:"Mental Health",title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        { image: love, des: { category:"Mental Health",title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        { image: yoga, des: { category:"Mental Health",title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        // { image: love, des: {category:"Mental Health", title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        {image:"view more"}
    ];

    return (
        <section className=" text-white pt-[30vh] bg-gradient-to-br from-[#2A2B45] to-[#130515] ">
            <h1 className="text-center text-[4vw] font-bold mb-[2vh]">Our Blogs</h1>
            <SliderComponent items={blogData} />
            
        </section>
    );
};

export default EightSection;
