import React, { useRef, useState } from 'react';
import BlogCard from '../Cards/BlogCard';
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import styled from 'styled-components';
import LeftArrow from "../../../../assets/images/LeftArrow.png";
import RightArrow from "../../../../assets/images/RightArrow.png";

const SliderContainer = styled.div`
    position: relative;
    width: 90%; 
    margin-left:65px;
    height:auto;
    overflow: visible; 
     margin-bottom: 30px

`;

const ArrowButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 10;


    img {
        width: 60px; 
        height: 60px; 
    }

    &:hover {
        opacity: 0.7;
    }
`;

const SliderComponent = ({ items }) => {
    const navigate = useNavigate();
    const carouselRef = useRef();
    const [currentIndex, setCurrentIndex] = useState(0);
    
    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        navigate('/blog');
    };

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const handleBeforeChange = (nextIndex) => {
        setCurrentIndex(nextIndex);
    };

    return (
        <div>
        <SliderContainer>
            <ArrowButton
                onClick={() => carouselRef.current.goToSlide(currentIndex - 2)}
                disabled={currentIndex === 0}
                style={{  top: '50%', cursor: currentIndex === 0 ? "not-allowed" : "" }} 
                className='-left-[3vw]'
            >
                <img src={LeftArrow} alt="Left Arrow"  />
            </ArrowButton>

            <Carousel
  responsive={responsive}
  beforeChange={(nextIndex) => handleBeforeChange(nextIndex)}
  ref={carouselRef}
  arrows={false}
  containerClass="carousel-container"
>
  {items.map((item, index) => (
    <div
      key={index}
      style={{
        position: 'relative',
        padding: '0 20px', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className='flex items-center justify-center mr-[2vw]'
    >
      {index <= items.length - 2 ? (
        <BlogCard image={item.image} des={item.des} />
      ) : (
        <div className='pt-[6.5vh]'>
          <div
            className='w-[40vw] h-[73vh] ml-[3vw] shadow-[#000000] flex justify-center items-center shadow-xl p-[2vw] rounded-xl cursor-pointer text-3xl font-bold transform transition-transform duration-300 hover:scale-110'
            onClick={() => {
              handleClick();
            }}
          >
            <h1 className="text-white text-center">Read More About Blogs</h1> {/* Center the text */}
          </div>
        </div>
      )}
    </div>
  ))}
</Carousel>


            <ArrowButton
                onClick={() => carouselRef.current.goToSlide(currentIndex + 2)}
                disabled={currentIndex === items.length - 2}
                style={{ top: '50%', cursor: currentIndex === items.length - 2 ? "not-allowed" : "" }} 
                className='-right-[3.5vw]'
            >
                <img src={RightArrow} alt="Right Arrow" className='' />
            </ArrowButton>
            </SliderContainer>

            <div className='justify-center flex '>
                <button
                    className={`bg-white text-center hover:bg-[#d9d9d9] hover:mt-[1vh] text-[1.5vw] font-bold text-black px-[5vw] py-[1vh]  rounded-full cursor-pointer`}
                    onClick={handleClick}
                >
                    Read More
                </button>
            </div>
            </div>

    );
};

export default SliderComponent;
