import React from 'react';
import logo from "../assets/images/Group 123 (1).png";
import Name from "../assets/images/Frame 33643.png";
import scanner from "../assets/images/Soundscape playstore 1 (1).webp"
import playstoreIcon from "../assets/images/image 314.webp"
import store from "../assets/images/pngegg (1) 1.webp"
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const handleScrollClick = (path) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path);
  };

  const handleNewTabClick = (url) => {
    window.open(url, '_blank'); 
  };

  return (
    <footer className='flex justify-between items-center bg-black text-white px-[3vw] py-[3vh]'>
      
      <div className='flex gap-[2vw] '>
        <img src={logo} className='w-[5vw] h-[20vh]' alt="Logo" />
        <div className='flex flex-col text-white/50 space-y-[4.5vh] text-[1.3vw] font-semibold cursor-pointer'>
          <div onClick={() => handleNewTabClick('/about-soundscape')}>About Us</div>
          <div onClick={() => handleScrollClick('/blog')}>Blogs</div>
          <div onClick={() => handleNewTabClick('/privacy')}>Privacy & Policy</div>
          {/* <div onClick={() => handleNewTabClick('/contact')}>Contact Us</div> */}
        </div>
      </div>

      <div className='flex flex-col items-center'>
        <h1 className='text-white/50 text-[1.7vw] font-semibold text-center'>
          To Access All Our Content Download Our App
        </h1>
        <img src={Name} className='w-[30vw] mt-[1vh]' alt="App Name" />
      </div>

      <div className='flex flex-col items-center gap-[1vw] cursor-pointer hover:opacity-50' onClick={()=>{
        window.open('https://play.google.com/store/apps/details?id=com.music.focusflow&pcampaignid=web_share', '_blank');
      }}>
        <img src={scanner} alt="QR code" className='w-[5vw] h-[10vh]'/>
        <div className='flex gap-[1vw] '>
          <img src={playstoreIcon} alt='playstoreIcon' className='w-[3vw] h-[6vh] mt-[3vh]'/>
          <img src={store} alt="text" className='w-[12vw] '/>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
