import React, { useState } from 'react';
import MobilePhone from "../../../assets/images/Rectangle (5).png";
import BackgroundImage1 from "../../../assets/images/photo1 (1).png";
import BackgroundImage2 from "../../../assets/images/photo2.png";
import HoverImage from "../../../assets/images/Rectangle (6).png";
import ExploreButton from './ExploreButton';
import { useNavigate } from 'react-router-dom';

const FifthSection = () => {
    const customStyle = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)',
        backdropFilter: 'blur(3px)',
    };
    const navigate = useNavigate();

    return (
        <section className=' text-white   relative bg-gradient-to-br from-[#38395C] to-[#251731] ' >
            <div className='bg-container overflow-hidden absolute'>
                <div className="scroll-background-left">
                    <img src={BackgroundImage1} className='bg1' alt="Background 1" />
                    <img src={BackgroundImage1} className='bg1' alt="Background 1" />
                </div>
            </div>
            <div className='flex mx-[4vw] image-container  ' > 
        <img
          src={MobilePhone}
          className='w-[29vw] h-[110vh] default-image z-40 -mt-[23vh] '
          alt="Mobile Phone"
          id="guided-section"
        />
        <img
          src={HoverImage}
          className='w-[29vw] h-[110vh] hovered-image z-40 -mt-[23vh]  '
          alt="Mobile Phone Hover"
        />
                <div className='z-20 ml-[20vw] mx-[5vw] w-full shadow-2xl shadow-black h-auto mt-[16vh] rounded-lg py-[5vh] pl-[8vw] pr-[4vw] text-center' style={customStyle}>
                    <h1 className='text-[2.5vw] font-bold mb-[2vh] ' >Peaceful Guided Meditation
                    </h1>
                    <h3 className='mb-[5vh] text-[1.3vw] ml-[2vw] mr-[3vw]'>Discover your inner calm with <b>Guided Meditations</b> Explore variety of themes and techniques to find the perfect practice for your needs. <b>Reduce stress</b> and find balance with <b>guided meditations</b> that help you <b>relax and focus</b> on what matters.
                    </h3>
                    
                        <ExploreButton path={'/guided'} />
                    
                </div>
            </div>
            <div className='bg-container overflow-hidden absolute -mt-[4vh]'>
                <div className="scroll-background-right">
                    <img src={BackgroundImage1} className='bg2' alt="Background 2" />
                    <img src={BackgroundImage1} className='bg2' alt="Background 2" />
                </div>
            </div>
        </section>
    );
};

export default FifthSection;
