import React from 'react';

const FeatureCard = ({ data }) => {
  return (
    <div className='h-[294px] w-[287px] text-center mt-10'>
      <div className='flex justify-center items-center'>
        <img src={data.image} alt={data.title} className='w-[140px] h-[140px]' />
      </div>
      <h1 className="text-[26.6px] font-bold mt-2">{data.title}</h1>
      <p className=" mt-4 text-[16.8px] px-5">{data.des}</p>
    </div>
  );
};

export default FeatureCard;
