import React, { useState } from 'react';
import backgroundImage from "../../assets/images/BackgroundDesign.png";
import Sleep from "../../assets/images/Rectangle 119.webp";
import Fitness from "../../assets/images/Group 7373.webp";
import Motivation from "../../assets/images/Group 7372.webp";
import DeepFocus from "../../assets/images/Group 7374.webp";
import Meditation from "../../assets/images/Group 7370.webp";
import Study from "../../assets/images/Group 7371.webp";
import Header from '../Header';
import Track from '../Track';
import Footer from '../Footer';


const TopPlaylist = () => {
    const [playingTrack, setPlayingTrack] = useState(null); // Track the currently playing track
    const text = "Top Playlists";
    const width = "w-[300px] h-[235px]";
    
    const tracksplaylist = [
        { songName: "Sleep", song: Sleep },
        { songName: "Fitness", song: Fitness },
        { songName: "Motivation", song: Motivation },
        { songName: "Meditation", song: Meditation },
        { songName: "Study", song: Study },
        { songName: "Deep Focus", song: DeepFocus }
    ];

    const itemHeight = 350;
    const height = tracksplaylist.length * itemHeight;



    const handleTrackClick = (songName) => {
        if (playingTrack === songName) {
            setPlayingTrack(null); 
        } else {
            setPlayingTrack(songName); 
        }
    };

    return (
        <div className='relative'>
            <div 
                className='bg-gradient-to-tr from-black to-[#342C43] w-full min-h-screen max-h-full z-10'
                style={{ minHeight: `${height}px` }} 
            >
                <Header image={backgroundImage} />
            </div>

            <div className='z-20 absolute top-0 left-0 mt-16 w-full'>
                {tracksplaylist.map((track, index) => (
                    <Track 
                        key={index} 
                        image={track.song} 
                        name={track.songName} 
                        width={width} 
                        Playing={playingTrack === track.songName}
                        onClick={() => handleTrackClick(track.songName)} 
                    />
                ))}
            </div>
            <Footer />
        </div>
    );
};

export default TopPlaylist;
