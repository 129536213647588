import React from 'react'
import Image1 from "../../../assets/images/Hero Image (9).webp"
import Image2 from "../../../assets/images/Hero Image (8).webp"
import Image3 from "../../../assets/images/Hero Image (7).webp"
import Image4 from "../../../assets/images/Hero Image (6).webp"

const FirstSection = () => {
  const handleImageClick = (path) => {
    const section = document.getElementById(path);
    section.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <section className=' text-white pt-[8vh] bg-gradient-to-b from-[#0E0C12] to-[#38395C]  '>
      {/* <div className='bg-gradient-to-tr from-black to-[#5D548A] w-full h-[550px]'>

      </div> */}
      <div className='z-50'>
      <h1 className='text-center font-bold text-[2.5vw] pt-10 wix-madefor-text-mixed ' >We can’t silence the world, but we can help you create your own oasis.</h1>
      <h3 className='text-center text-[1.5vw] pt-5 px-[10vw] z-50 wix-madefor-text-regular '>We're here to help you feel better.  Find instant relief from <b>stress and anxiety</b> with <b>guided meditations, soothing brainwaves,</b> and calming <b>sleep stories</b>. Dive into a peaceful sleep and Wake up refreshed and focused. 
      </h3>
      <div className="flex mt-[8vh] justify-around  overflow-hidden py-[4vh]">
  <img
    src={Image1}
    alt="Brainwaves"
    className="w-[27vw] h-[50vh] cursor-pointer transform transition-transform duration-300 hover:scale-110"
    onClick={() => {
      handleImageClick("brainwaves-section");
    }}
  />
  <img
    src={Image2}
    alt="guided"
    className="w-[20vw] h-[50vh] cursor-pointer transform transition-transform duration-300 hover:scale-110"
    onClick={() => {
      handleImageClick("guided-section");
    }}
  />
  <img
    src={Image3}
    alt="radio"
    className="w-[20vw] h-[50vh] cursor-pointer transform transition-transform duration-300 hover:scale-110 hover:shadow-black"
    onClick={() => {
      handleImageClick("radio-section");
    }}
  />
  <img
    src={Image4}
    alt="stories"
    className="w-[24vw] h-[50vh] cursor-pointer transform transition-transform duration-300 hover:scale-110"
    onClick={() => {
      handleImageClick("sleep-section");
    }}
  />
</div>

      </div>
    

    </section>
  )
}

export default FirstSection