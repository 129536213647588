import React from 'react'
import Header from './Header'
import image from "../assets/images/Vector 228.png"
import Card1 from "../assets/images/Vector 227.png"
import Card2 from "../assets/images/Vector 227 (1).png"
import Card3 from "../assets/images/Vector 230.png"
import feature1 from "../assets/images/Group 7392.png"
import feature2 from "../assets/images/Group 7393.png"
import feature3 from "../assets/images/Group 7394.png"
import feature5 from "../assets/images/Group 7395.png"
import feature6 from "../assets/images/Group 7396.png"
import feature4 from "../assets/images/Group 7397.png"
import AboutCard from './AboutCard'
import FeatureCard from './FeatureCard'
import Footer from './Footer'
const AboutUs = () => {
    const cards=[{
        image:Card1,
        title:"Who We Are",
        des:"Founded in 2023, Soundscape offers curated nature sounds, ambient melodies, and calming tones to bring focus, balance, and peace in a hectic world."
    },
    {
        image:Card2,
        title:"What We Do?",
        des:"We have created a library of Solfeggio frequencies, Binaural beats, ambient melodies, and calming tones to help you relax, focus and get better sleep through a hectic schedule. "
    },
    {
        image:Card3,
        title:"How We Do It?",
        des:"Life can be overwhelming. Finding the right sounds shouldn’t be. Soundscape offers curated playlists for every mood, live radio, and calming nature sounds. Enhance your "
    }];
    const features=[
        {
            image:feature1,
            title:"Soundscapes",
            des:"A library of soothing sounds for your daily needs and different moods."
        },
        {
            image:feature2,
            title:"Stories",
            des:"A collection of stories that are perfect for unwinding and falling asleep."
        },
        {
            image:feature3,
            title:"Live Radio",
            des:"A library of soothing sounds for your daily needs and different moods."
        },
        {
            image:feature4,
            title:"Brainwaves",
            des:"Our collection of different solfeggio frequencies and binaural beats."
        },
        {
            image:feature5,
            title:"Guided Meditations",
            des:"Our easy guided meditations to relax and release stress."
        },
        {
            image:feature6,
            title:"Blogs",
            des:"Check out our blogs for helpful Tips and Ideas on how to boost your mood."
        },
    ]
  return (
    <div className='text-white'>
    <div className='bg-gradient-to-br from-black to-[#342C43] w-full h-full '>
        <Header/>
        

        <div className=' absolute left-[35%] right-[5%] top-[30%] '>
<h1 className='font-bold text-[70px]'>About Us</h1>
<p className='text-[20px]'>Soundscape offers a collection of sounds and frequencies to make you feel more relaxed and focused with our easy-to-use app whenever you need it.</p>
        
    </div>
    <div className='pt-[20vh]'>
            <img src={image} className='w-full h-full object-cover'/>
        </div>
        <div className='flex justify-around items-center mt-32'>
        {cards.map((i)=>{
           return <AboutCard data={i} />
        })}
        </div>

        <div className='mt-20 text-center mx-14 text-[20px]'>
         <h1 className='text-[26.6px] font-bold'>Our Mission</h1>
         <p className='mt-10 mx-20 text-justify'>Multiple researches have proven that sound has the power to change moods, enhance focus, and help relax. We are committed to providing a unique and relaxing experience for everyone in their daily routine. We have designed a customized sound experience to meet your specific needs Whether it's easing stress, improving mood, or enhancing sleep quality, the soundscape is available to assist everyone in their journey to better health with sounds.</p>
         <p className='mt-7'>Join us at Soundscape and discover the perfect soundtrack for your life.</p>
       
       </div>
       <div className='bg-gradient-to-t from-[#272840] '>
        <div className='flex justify-center items-center flex-wrap gap-[7px] pt-14 pb-28'>
            {features.map((i)=>{
                return <FeatureCard data={i}/>
            })}
        </div>
       </div>
       </div>
       <Footer/>
    </div>
  )
}

export default AboutUs