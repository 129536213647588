import React from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import BlogCard from '../Home/Sections/Cards/BlogCard';
import arrow from "../../assets/images/Group 7348.png";
import frame from "../../assets/images/image 276 (1).webp";
import yoga from "../../assets/images/BolgImage1.png";
import love from "../../assets/images/BlogImage2.png";
import Footer from '../Footer';
const BlogDes = () => {
    const navigate=useNavigate();
    const blogData = [
        { image: yoga, des: {category:"Mental Health", title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        { image: love, des: {category:"Mental Health", title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
    ]
  return (
    <div className=' bg-gradient-to-tr from-black to-[#423956] text-white min-h-screen max-h-full z-30 '>
        <Header image={frame}/>
        <div className='flex flex-col absolute ml-10 gap-3 pt-[10vh] '>
        <img src={arrow} className=' w-10 h-10 cursor-pointer z-40 ' onClick={()=>{
            navigate('/blog')
        }}/>
        <div className=' text-[18px] font-bold flex flex-col gap-3'>
         <h1 className=' underline '>In This Article</h1>
         <h3>Heading 1</h3>
         <h3>Heading 2</h3>
         <h3>Heading 3</h3>
         <h3>Heading 4</h3>
        </div>
        </div>

        <div className=' z-40 pt-[10vh]'>
            <div className='ml-52 mr-44'>
        <h1 className='text-[40px] font-bold '>How brainwaves are effective?</h1>
        <div className='flex text-[#00A3EA] mt-10 mb-5 justify-between text-[18px]'>
            <span>Author Name</span><span>05 Aug 2024, Monday</span>
        </div>
        <div className=' mb-6 text-[18px] text-justify '>
            <p>Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy.</p>
        </div>
        <p className='text-[#00A3EA]'>#Brainwaves  #Focusbetter  #Concentration</p>
        </div>
        <div className='font-bold text-[33.6px] mt-10 ml-52'>Check out more blogs</div>
        <div className='flex ml-36 mr-44  pb-20 gap-8' >
             {blogData.map((i)=>{
                return   <BlogCard image={i.image} des={i.des} width={400} />
             })}
        </div>
        </div>
        <Footer/>
    </div>
  )
}

export default BlogDes