import React from 'react';
import MobilePhone from "../../../assets/images/Group 7367.png";
import HoverImage from "../../../assets/images/Group 7366.png"
import BackgroundImage2 from "../../../assets/images/BackgroundImage4.png"
import BackgroundImage1 from "../../../assets/images/backgroundImage5.png"
import ExploreButton from './ExploreButton';
import { useNavigate } from 'react-router-dom';

const SeventhSection = () => {
    const customStyle = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)',
        backdropFilter: 'blur(3px)',
    };

    return (
        <section className=' text-white  relative bg-gradient-to-br from-[#2A2B45] to-[#2A2B45] ' >
        {/* <img src={BackgroundImage2} className='absolute h-[112.35px] w-full' alt="Background" /> */}
        <div className='bg-container overflow-hidden absolute '   >
        <div className="scroll-background-left" >
          <img src={BackgroundImage2} className='bg1' alt="Background 1" />
          <img src={BackgroundImage2} className='bg1' alt="Background 1" />
        </div>
      </div>
      <div className='flex mx-[4vw] image-container   '  >
        <img
          src={MobilePhone}
          className='w-[35vw] h-[100vh] default-image z-40 -mt-[20vh] '
          alt="Mobile Phone"
          id="brainwaves-section"
        />
        <img
          src={HoverImage}
          className='w-[35vw] h-[100vh] hovered-image z-40 -mt-[20vh]  '
          alt="Mobile Phone Hover"
        />
    <div className='z-20  ml-[20vw] mr-[4vw] w-full shadow-2xl shadow-black h-auto mt-[16vh] rounded-lg py-[5vh] pl-[15vw] pr-[4vw]  text-center ' style={customStyle}>
        <h1 className='text-[2.5vw] font-bold mb-[2vh]'>Brainwaves
        </h1>
        <h3 className='mb-[5vh] text-[1.3vw] ml-[2vw] mr-[3vw] '>Discover your ideal frequency with our Brainwave tracks, carefully tailored to meet your individual needs. Experience the transformative power of Brainwaves in your daily life, enhancing your focus, reducing stress, and fostering a deeper sense of well-being.</h3>
        
        
<ExploreButton path={'/brainwaves'} />
        

    </div>
           
        </div>
        {/* <img src={BackgroundImage1} className=' -mt-8 ' alt="Background" /> */}
        <div className='bg-container overflow-hidden absolute -mt-[4vh]'>
        <div className="scroll-background-right">
          <img src={BackgroundImage1} className='bg2' alt="Background 1" />
          <img src={BackgroundImage1} className='bg2' alt="Background 1" />
        </div>
        </div>
    </section>
    );
};

export default SeventhSection;
