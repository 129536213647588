import React from 'react'
import logo from "../assets/images/Frame 33599.png"
const PrivacyPolicy = () => {
    const customStyle = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
        backdropFilter: 'blur(3px)',
    };


  return (
    <div className='bg-gradient-to-tr from-black to-[#342C43] w-full min-h-screen max-h-full text-white pb-12 text-justify '>
        <div className='flex justify-center items-center w-full h-[67px] ' style={customStyle}>
            <img src={logo} className='w-[300px] h-[60px]'/>

        </div>
        <div className='mx-48 mt-10 text-[24px] '>
        <h1 className='font-bold text-[40px] mb-8'>Soundscape Privacy Policy</h1>
        <p className=''>At Soundscape, we value your privacy and are committed to protecting the personal information of our website visitors. This Privacy Policy outlines how we handle information collected from users who visit our website. Please take a moment to read this policy to understand how your data is handled</p>
        <h2 className='underline font-bold text-[30px] mt-10'>Information We Collect</h2>
        <p className='mt-5'>We do not require or collect any personal information such as names, email addresses, or payment details from visitors to our website. You can browse the website content without logging in, signing up, or providing any personal data.</p>
        <p className=''>The only information we collect is basic, non-personal data that helps us understand how our visitors use the website. This may include:</p>
        <ol className='list-disc list-inside text-white '>
  <li>Browser Type and Version</li>
  <li>Operating System</li>
  <li>IP address (collected anonymously)</li>
</ol>

        <p className=''>This data is collected through cookies and analytics tools to help us improve the user experience and ensure the smooth functioning of the website.</p>
        <h2 className='underline font-bold text-[30px] mt-10'>Security</h2>
       <p className='mt-5'>While we do not collect personal data, we implement reasonable security measures to protect the basic information we collect from unauthorized access. However, please note that no online service is completely secure.</p>
       <h2 className='underline font-bold text-[30px] mt-10'>Changed To This Policy</h2>
        <p className='mt-5'>We may update this Privacy Policy occasionally to reflect changes in our practices or for legal and regulatory reasons. We encourage you to review this page periodically to stay informed of any updates.</p>
        <h2 className='underline font-bold text-[30px] mt-10'>Contact Us</h2>
        <p className='mt-5'>If you have any questions, concerns, or suggestions regarding this Privacy Policy or our privacy practices, please contact us at sashidhar.dev@icloud.com.</p>
        <p className=''>By using the Soundscape website, you agree to this Privacy Policy</p>
        </div>


    </div>
  )
}

export default PrivacyPolicy