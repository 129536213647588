import React from 'react'
import Header from '../Header'
import FirstSection from './Sections/FirstSection';
import SecondSection from './Sections/SecondSection';
import ThirdSection from './Sections/ThirdSection';
import FourthSection from './Sections/FourthSection';
import FifthSection from './Sections/FifithSection';
import SixthSection from './Sections/SixthSection';
import SeventhSection from './Sections/SeventhSection';
import EightSection from './Sections/EightSection';
import LastSection from './Sections/LastSection';
import Footer from '../Footer';
import BackgroundDesign from "../../assets/images/BackgroundDesign.png"
import VideoSection from './Sections/VideoSection';
import HomeSection from './Sections/HomeSection';
import QuestionSection from './Sections/QuestionSection';

const Home = () => {
  return (
    
          <div className='bg-gradient-to-br from-black to-[#5D548A] opacity-100 h-full '>
        <Header image={BackgroundDesign}/>
        <HomeSection/>
        <ThirdSection/>
        <FirstSection/>
        {/* <SecondSection/> */}
       
        <FourthSection/>
        <FifthSection/>
        <VideoSection/>
        <SixthSection/>
        <SeventhSection/>
        <EightSection/>
        <LastSection/>
        <QuestionSection/>
        <Footer/>
    </div>
  )
}

export default Home;