import React from 'react'

const RadioCard = ({image,songName}) => {
  return (
    <div>
    <div className='rounded-lg w-72'>
     <img src={image} className='cursor-pointer z-30'/>
    </div>
    <h3 className='text-center mt-2'>{songName}</h3>
    </div>
  )
}

export default RadioCard